import axios from 'axios';
import { LANGUAGE_HEADER_KEY } from './constants';
export const SELECTED_COMPANY_LS_KEY = 'selectedCompany';
export const SELECTED_COMPANY_NAME_LS_KEY = 'selectedCompanyName';
export const LAST_SELECTED_LANGUAGE_LS_KEY = 'last_selected_language';
export const LOCAL_STORAGE_ACCESS_TOKEN_LS_KEY = 'accessToken';
export const LOCAL_STORAGE_LOGGED_IN_USER_EMAIL_KEY = 'logged_in_user_email';
export const SELECTED_COMPANY_HEADER = 'x-company-name';
export const API_AUTHORIZATION_HEADER = 'authorization';
export const API_TOKEN_TYPE = 'Bearer';
const isCms = !!process.env.REACT_APP_API_URL;
export const globalApiClient = axios.create({
    baseURL: 'https://reservation-app-api-1.onlinereservationsites.com',
    withCredentials: isCms ? true : undefined,
});
globalApiClient.defaults.timeout = 15000;
globalApiClient.interceptors.request.use((req) => {
    try {
        const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_LS_KEY);
        const selectedCompanyName = localStorage.getItem(SELECTED_COMPANY_NAME_LS_KEY);
        const lastSelectedLanguage = localStorage.getItem(LAST_SELECTED_LANGUAGE_LS_KEY);
        if (accessToken && isCms) {
            req.headers[API_AUTHORIZATION_HEADER] = `${API_TOKEN_TYPE} ${accessToken}`;
        }
        if (lastSelectedLanguage) {
            req.headers[LANGUAGE_HEADER_KEY] = lastSelectedLanguage;
        }
        if (isCms && selectedCompanyName) {
            req.headers[SELECTED_COMPANY_HEADER] = selectedCompanyName;
        }
        if (!isCms) {
            req.headers[SELECTED_COMPANY_HEADER] = process.env.GATSBY_COMPANY_NAME;
        }
        return req;
    }
    catch (error) {
        return Promise.reject(error);
    }
});
globalApiClient.interceptors.response.use((res) => {
    var _a;
    try {
        if (((_a = res.data) === null || _a === void 0 ? void 0 : _a.accessToken) && isCms) {
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_LS_KEY, res.data.accessToken);
        }
        return res;
    }
    catch (error) {
        return Promise.reject(error);
    }
});
